import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import Svg from "../atoms/Svg";

const sections = [
  { url: "/", name: "Test", icon: "pencil.svg" },
  { url: "/user-stats", name: "Statystyki", icon: "chart-bar.svg" },
  { url: "/settings", name: "Profil", icon: "user.svg" },
];

const Nav = () => {
  const currentLocation = useLocation();

  return (
    <div className="max-sm:fixed bottom-0 left-0 w-full h-20 sm:p-5 max-sm:border-t sm:border-b max-sm:bg-slate-50">
      <ul className="grid grid-rows-1 grid-flow-col sm:justify-center justify-items-center items-center sm:gap-20 h-full text-xl font-semibold">
        {sections.map((section, index) => {
          return (
            <li
              key={index}
              className="w-full h-full max-sm:border-r last:border-none"
            >
              <NavLink
                to={section.url}
                className={({ isActive }) => {
                  return `flex flex-col sm:flex-row justify-center items-center sm:gap-2
                  w-full h-full
                  -outline-offset-8 sm:outline-offset-8
                  hover:text-blue-600 max-sm:hover:bg-blue-50
                  ${isActive && "text-blue-600 hover:text-blue-800"}
                  hover-underline-animation-wrapper`;
                }}
              >
                <Svg
                  src={`/icons/${section.icon}`}
                  className="self-center w-6"
                />
                <span
                  className={
                    currentLocation.pathname === section.url
                      ? "hover-underline-animation-stay"
                      : "hover-underline-animation"
                  }
                >
                  {section.name}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Nav;
