import React, { useEffect, useState } from 'react';

const StatisticsScreen = ({ problemsData, sessionStartTime }) => {
  const totalAttempted = problemsData.length;
  const solvedCount = problemsData.filter(problem => problem.is_solved).length;
  const solvedRatio = totalAttempted ? ((solvedCount / totalAttempted) * 100).toFixed(0) : 0;

  const [fieldMapping, setFieldMapping] = useState({});
  const [elapsedTime, setElapsedTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const availableFields = JSON.parse(localStorage.getItem("available_fields")) || [];
    const mapping = availableFields.reduce((acc, field) => {
      acc[field.field_id] = field.field_name;
      return acc;
    }, {});
    setFieldMapping(mapping);
  }, []);

  useEffect(() => {
    const updateElapsedTime = () => {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const elapsedSeconds = currentTime - sessionStartTime;

      const hours = Math.floor(elapsedSeconds / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;

      setElapsedTime({ hours, minutes, seconds });
    };

    // Initial update and setting interval to update every second
    updateElapsedTime();
    const intervalId = setInterval(updateElapsedTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [sessionStartTime]);

  const categoryEloChange = problemsData.reduce((acc, problem) => {
    acc[problem.field_id] = (acc[problem.field_id] || 0) + problem.elo_change;
    return acc;
  }, {});

  return (
    <div className="flex flex-col items-center gap-4 w-full max-w-xl bg-white rounded-lg shadow-lg p-6">
      <h2 className="font-bold text-2xl mb-4 text-center">Statystyki sesji</h2>

      <p className="text-gray-700">
        Czas trwania sesji: 
        {elapsedTime.hours > 0 && ` ${elapsedTime.hours}h`}
        {elapsedTime.minutes > 0 && ` ${elapsedTime.minutes}m`}
        {` ${elapsedTime.seconds}s`}
      </p>
      <p className="text-gray-700">Skuteczność: {solvedRatio}%</p>

      <h3 className="font-bold text-lg mt-4">Obecna zmiana ELO</h3>
      {Object.entries(categoryEloChange).map(([field_id, eloChange]) => {
        const sign = eloChange > 0 ? '+' : eloChange < 0 ? '-' : '';
        const colorClass = eloChange > 0 ? 'text-green-500' : eloChange < 0 ? 'text-red-500' : 'text-gray-700';
        return (
          <p key={field_id} className="text-gray-700">
            {fieldMapping[field_id] || `ID: ${field_id}`}: <span className={colorClass}>{sign}{Math.abs(eloChange)}</span>
          </p>
        );
      })}
    </div>
  );
};

export default StatisticsScreen;
