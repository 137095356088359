import React from "react";
import Nav from "./Nav";

const Page = (props) => {
  const { showNav, children } = props;

  return (
    <div className="flex flex-col">
      {showNav && <Nav />}
      <div
        className={`flex-1 flex flex-col justify-center items-center gap-10 ${
          showNav && "max-sm:pb-20"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Page;
