import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Page from "../molecules/Page";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const Dashboard = ({ setProblemsData, setUserEloDict }) => {
  const [username, setUsername] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [problemsCount, setProblemsCount] = useState(0);
  const [averageUserElo, setAverageUserElo] = useState(0);
  const [levelModifier, setLevelModifier] = useState(0);
  const [mobileProblems, setMobileProblems] = useState(false); // Pozostawiamy zadania bez obliczeń
  const [newFieldId, setNewFieldId] = useState("");

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}user_dashboard_data/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;
        setUsername(data.username);
        setSelectedFields(data.selected_fields);
        setAvailableFields(data.available_fields);
        localStorage.setItem(
          "available_fields",
          JSON.stringify(data.available_fields)
        );
        setProblemsCount(data.problems_count);
        setAverageUserElo(data.average_user_elo);
        setLevelModifier(data.preferences.level_modifier);
        setMobileProblems(data.preferences.mobile_problems);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        if (error.response.status == 401) {
          navigate("/login");
        }
      }
    };

    fetchData();
  }, [token]);

  const handleAddField = () => {
    const newField = availableFields.find(
      (field) => field.field_id === parseInt(newFieldId)
    );
    if (
      newField &&
      !selectedFields.some((field) => field.field_id === newField.field_id)
    ) {
      setSelectedFields([...selectedFields, newField]);
    }
  };

  const handleRemoveField = (fieldId) => {
    setSelectedFields(
      selectedFields.filter((field) => field.field_id !== fieldId)
    );
  };

  const handleSolveProblems = async () => {
    const selectedFieldIds = selectedFields.map((field) => field.field_id);
    const preferences = {
      level_modifier: levelModifier,
      mobile_problems: mobileProblems,
    };

    try {
      const response = await axios.post(
        `${apiUrl}start_solve_problems/`,
        {
          preferences,
          selected_fields: selectedFieldIds,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setUserEloDict(response.data.user_elo_dict);
      setProblemsData(response.data.final_problems);
      navigate("/solve-problem");
    } catch (error) {
      console.error("Error starting solve problems:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${apiUrl}user_logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Page showNav>
      {username ? (
        <>
          <div className="space-y-2 text-center">
            <h1>Świetnie {username}!</h1>
            <p>
              Rozwiązałeś z nami już{" "}
              <span className="text-xl font-bold text-blue-600">
                {problemsCount}
              </span>{" "}
              zadań.
            </p>
            <p>
              Twój obecny ranking to{" "}
              <span className="text-xl font-bold text-blue-600">
                {averageUserElo}
              </span>
              .
            </p>
            <button onClick={() => navigate("/user-stats")} className="btn">
              Zobacz swoje statystyki
            </button>
            <button
              onClick={handleLogout}
              className="btn bg-red-500 hover:bg-red-700"
            >
              Wyloguj się
            </button>
          </div>

          <div className="grid gap-6 p-4 border rounded-xl">
            <h2 className="text-center">Rozwiązuj zadania!</h2>
            <div>
              <h3>Wybrane kategorie:</h3>
              <table className="w-full">
                <tbody>
                  {selectedFields.map((field) => (
                    <tr key={field.field_id}>
                      <td>{field.field_name}</td>
                      <td>{field.user_field_elo}</td>
                      <td className="flex justify-end">
                        <button
                          className="bg-red-400 hover:bg-red-600"
                          onClick={() => handleRemoveField(field.field_id)}
                        >
                          Usuń
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex gap-4">
              <select
                className="rounded border border-slate-300"
                onChange={(e) => setNewFieldId(e.target.value)}
                value={newFieldId}
              >
                <option value="">Wybierz kategorię</option>
                {availableFields.map((field) => (
                  <option key={field.field_id} value={field.field_id}>
                    {field.field_name}
                  </option>
                ))}
              </select>
              <button onClick={handleAddField} className="btn">
                Dodaj kategorię
              </button>
              <button onClick={() => navigate("/fields-preferences")}>
                Zarządzaj Polami
              </button>
            </div>

            <form
              className="flex flex-col gap-2"
              onSubmit={(e) => {
                e.preventDefault();
                handleSolveProblems();
              }}
            >
              <div className="flex flex-col gap-1">
                <label htmlFor="difficulty">Poziom trudności</label>
                <select
                  id="difficulty"
                  className="max-w-52 rounded border border-slate-300"
                  value={levelModifier}
                  onChange={(e) => setLevelModifier(parseInt(e.target.value))}
                >
                  <option value={-300}>Łatwy</option>
                  <option value={0}>Średni</option>
                  <option value={300}>Trudny</option>
                </select>
              </div>

              <div className="flex gap-2">
                <input
                  id="mobile_problems"
                  type="checkbox"
                  checked={mobileProblems}
                  onChange={(e) => setMobileProblems(e.target.checked)}
                />
                <label htmlFor="mobile_problems">Zadania bez obliczeń</label>
              </div>

              <button type="submit" className="mx-auto">
                Rozwiązuj!
              </button>
            </form>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Page>
  );
};

export default Dashboard;
