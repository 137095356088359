import React, { useState } from "react";
import Page from "../molecules/Page";

const apiUrl = process.env.REACT_APP_API_SOURCE; // Zmienna środowiskowa z adresem API

const Settings = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setError("Nowe hasła muszą być takie same.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${apiUrl}change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`, // Jeśli jest wymagana autoryzacja, dodajemy token
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        setMessage("Hasło zostało zmienione pomyślnie.");
      } else {
        const errorData = await response.json();
        setError(errorData?.message || "Wystąpił błąd. Spróbuj ponownie.");
      }
    } catch (err) {
      setError("Błąd połączenia. Sprawdź swoje połączenie i spróbuj ponownie.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page showNav>
      <div style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
        <h2>Zmień hasło</h2>
        {message && <p style={{ color: "green" }}>{message}</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="old-password">Stare hasło:</label>
            <input
              className="rounded border border-slate-300"
              type="password"
              id="old-password"
              value={oldPassword}
              onChange={handleOldPasswordChange}
              required
              style={{ width: "100%", padding: "10px", margin: "10px 0" }}
            />
          </div>
          <div>
            <label htmlFor="new-password">Nowe hasło:</label>
            <input
              className="rounded border border-slate-300"
              type="password"
              id="new-password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              required
              style={{ width: "100%", padding: "10px", margin: "10px 0" }}
            />
          </div>
          <div>
            <label htmlFor="confirm-password">Potwierdź nowe hasło:</label>
            <input
              className="rounded border border-slate-300"
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              style={{ width: "100%", padding: "10px", margin: "10px 0" }}
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
          >
            {isSubmitting ? "Wysyłanie..." : "Zmień hasło"}
          </button>
        </form>
      </div>
    </Page>
  );
};

export default Settings;
