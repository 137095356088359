// src/Login.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_SOURCE;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  console.log("login_page");
  console.log(apiUrl);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl + "login/", {
        username,
        password,
      });

      if (response.status === 200) {
        const { token, preferences } = response.data;

        // Zapisz token i preferencje użytkownika w localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("user_id", preferences.user_id);
        localStorage.setItem("user_preferences", JSON.stringify(preferences));

        // Przekieruj do strony głównej
        navigate("/");
      }
    } catch (error) {
      setError("Błędna nazwa użytkownika lub hasło");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-4 w-full max-w-xs mx-auto">
      <h1>Logowanie</h1>
      <form className="flex flex-col gap-4 w-full" onSubmit={handleLogin}>
        <div className="flex flex-col gap-1">
          <label for="username" className="font-medium">
            Nazwa użytkownika
          </label>
          <input
            id="username"
            className="p-2 rounded border border-slate-300"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label for="password" className="font-medium">
            Hasło
          </label>
          <input
            id="password"
            className="p-2 rounded border border-slate-300"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit" className="mx-auto">
          Zaloguj
        </button>
        <p className="text-center">
          Nie masz konta?{" "}
          <a
            href="/register"
            className="text-blue-600 hover:text-blue-800 hover:underline"
          >
            Zappgraduj się!
          </a>
        </p>
        <p className="text-center">
           Zapomniałeś hasła?{" "}
          <a
            href="/password_reset"
            className="text-blue-600 hover:text-blue-800 hover:underline"
          >
            Zresetuj je tutaj!
          </a>
        </p>
      </form>
    </div>
  );
};

export default Login;
