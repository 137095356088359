import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

export const cleanLatexString = (str) => {
    if (typeof str !== 'string') return str;
    let cleaned = str.replace(/\\\\/g, '\\');
    cleaned = cleaned.replace(/\\'/g, "'");
    cleaned = cleaned.replace(/\\n/g, ' $\\\\$ ');
    cleaned = cleaned.replace(/^1./g, '`1`. ');
    return cleaned;
};

export const RenderMarkdownWithLatex = ({ text }) => {
    const cleanText = cleanLatexString(text); // Wywołaj swoją funkcję do czyszczenia tekstu

    return (
        <ReactMarkdown
            children={cleanText}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
        />
    );
};