import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RenderMarkdownWithLatex } from "../../utils/utils";
import StatisticsScreen from "./Tooltip"; // Importuj nowy komponent Tooltip
import { FaChartBar } from "react-icons/fa"; // Importuj ikonę
import Page from "../molecules/Page";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const SolveProblem = ({
  userEloDict,
  setProblemsData,
  setUserEloDict,
  problemsData,
}) => {
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(null);
  const [sessionStartTime, setSessionStartTime] = useState(
    Math.floor(Date.now() / 1000)
  );
  const [currentProblem, setCurrentProblem] = useState(problemsData[0]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isHintUsed, setIsHintUsed] = useState(false);
  const [isSolutionViewed, setIsSolutionViewed] = useState(false);
  const [isSolutionConfirmationVisible, setIsSolutionConfirmationVisible] =
    useState(false);
  const [endTime, setEndTime] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [showIcons, setShowIcons] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [problemsSession, setProblemsSession] = useState([]);
  const [userFieldElo, setUserFieldElo] = useState(0);
  const [hasConfirmedAnswer, setHasConfirmedAnswer] = useState(false); // Dodany stan
  const [showSessionStats, setShowSessionStats] = useState(false); // Nowy stan dla wyświetlania statystyk sesji
  const [showSessionTooltip, setShowSessionTooltip] = useState(false); // Nowy stan
  const [problemNumber, setProblemNumber] = useState(1); // Nowy stan
  const [eloChange, setEloChange] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    setStartTime(currentTime);
  }, [currentProblem]);

  useEffect(() => {
    if (currentProblem) {
      const shuffledAnswers = [
        { text: currentProblem.true_answer, isCorrect: true },
        { text: currentProblem.false_answer1, isCorrect: false },
        { text: currentProblem.false_answer2, isCorrect: false },
        { text: currentProblem.false_answer3, isCorrect: false },
      ].sort(() => Math.random() - 0.5);
      setAnswers(shuffledAnswers);
      setUserFieldElo(userEloDict[currentProblem.field_id]);
    }
  }, [currentProblem]);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    setShowIcons(true);
  };
  // Funkcja wyświetlania statystyk sesji
  const handleShowSessionStats = () => {
    setShowSessionStats((prev) => !prev);
  };

  const handleShowSessionTooltip = () => {
    setShowSessionTooltip(true);
  };

  const handleCloseTooltip = () => {
    setShowSessionTooltip(false);
  };

  // Funkcja powrotu do rozwiązywania zadań
  const handleCloseSessionStats = () => {
    setShowSessionStats(false);
  };

  const handleConfirmClick = async () => {
    setTimeout(() => {
      setShowIcons(false);
    }, 100);

    setEndTime(Math.floor(Date.now() / 1000));
    const isCorrect = selectedAnswer.isCorrect;

    if (!isCorrect) {
      setShowSolution(true);
    }

    setIsAnswerChecked(true);
    setIsSubmitDisabled(true);
    setHasConfirmedAnswer(true); // Ustawiamy na true po potwierdzeniu odpowiedzi

    const problemAttemptData = {
      problem_id: currentProblem.problem_id,
      field_id: currentProblem.field_id,
      is_solved: isCorrect,
      is_hint_used: isHintUsed,
      is_solution_viewed: isSolutionViewed,
      start_time: startTime,
      end_time: Math.floor(Date.now() / 1000),
    };

    try {
      const response = await fetch(`${apiUrl}solve_problem_attempt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(problemAttemptData),
      });

      const data = await response.json();
      setEloChange(data.attempt_elo_change);

      setProblemsSession((prev) => [
        ...prev,
        {
          problem_id: currentProblem.problem_id,
          field_id: currentProblem.field_id,
          is_solved: selectedAnswer.isCorrect,
          is_solution_viewed: isSolutionViewed,
          is_hint_used: isHintUsed,
          user_elo: userFieldElo,
          problem_elo: currentProblem.problem_elo,
          elo_change: data.attempt_elo_change,
          question: currentProblem.question,
          solution: currentProblem.solution,
          answers: answers,
          true_answer: currentProblem.true_answer,
          selected_answer: selectedAnswer.text,
          start_time: startTime,
          end_time: Math.floor(Date.now() / 1000),
        },
      ]);

      setUserEloDict(data.user_elo_dict);
    } catch (error) {
      console.error("Błąd podczas wysyłania danych:", error);
    }
  };

  const handleSolutionConfirmClick = async () => {
    // Jeśli użytkownik wcześniej potwierdził odpowiedź, nie wysyłaj danych ani nie pokazuj potwierdzenia
    if (hasConfirmedAnswer) {
      setShowSolution(true);
      return;
    }

    setIsSolutionViewed(true);
    setIsSolutionConfirmationVisible(false);
    setShowSolution(true);
    setEndTime(Math.floor(Date.now() / 1000));
    setIsAnswerChecked(true);
    setIsSubmitDisabled(true);

    const problemAttemptData = {
      problem_id: currentProblem.problem_id,
      field_id: currentProblem.field_id,
      is_solved: false,
      is_hint_used: isHintUsed,
      is_solution_viewed: true,
      start_time: startTime,
      end_time: Math.floor(Date.now() / 1000),
    };

    try {
      const response = await fetch(`${apiUrl}solve_problem_attempt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(problemAttemptData),
      });

      const data = await response.json();
      setEloChange(data.attempt_elo_change);

      setProblemsSession((prev) => [
        ...prev,
        {
          problem_id: currentProblem.problem_id,
          field_id: currentProblem.field_id,
          is_solved: 0,
          is_solution_viewed: 1,
          is_hint_used: isHintUsed,
          problem_elo: currentProblem.problem_elo,
          user_elo: userFieldElo,
          elo_change: data.attempt_elo_change,
          question: currentProblem.question,
          solution: currentProblem.solution,
          answers: answers,
          true_answer: currentProblem.true_answer,
          selected_answer: null,
          start_time: startTime,
          end_time: Math.floor(Date.now() / 1000),
        },
      ]);

      setUserEloDict(data.user_elo_dict);
    } catch (error) {
      console.error("Błąd podczas wysyłania danych:", error);
    }
  };

  const handleSolution = () => {
    if (hasConfirmedAnswer) {
      setShowSolution(true); // Jeśli użytkownik potwierdził odpowiedź, od razu pokazujemy rozwiązanie
    } else {
      setIsSolutionConfirmationVisible(true); // Inaczej pokazujemy potwierdzenie
    }
  };

  // Reszta kodu pozostaje bez zmian
  const handleNextProblem = async () => {
    if (problemsData.length > 1) {
      problemsData.shift();
      setCurrentProblem(problemsData[0]);
    } else {
      try {
        const response = await fetch(`${apiUrl}continue_solve_problems/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        const data = await response.json();
        setUserEloDict(data.user_elo_dict);
        setProblemsData(data.final_problems);

        if (data.final_problems.length > 0) {
          setCurrentProblem(data.final_problems[0]);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania nowych zadań:", error);
      }
    }

    setIsHintUsed(false);
    setIsSolutionViewed(false);
    setIsAnswerChecked(false);
    setSelectedAnswer(null);
    setEndTime(null);
    setIsSubmitDisabled(false);
    setIsSolutionConfirmationVisible(false);
    setShowIcons(false);
    setShowSolution(false);
    setProblemNumber(problemNumber + 1);
    setEloChange(null);
    setHasConfirmedAnswer(false); // Resetujemy stan przy przejściu do nowego zadania
  };

  const handleHint = () => {
    setIsHintUsed(true);
  };

  const handleEndSession = () => {
    localStorage.setItem("problemsSession", JSON.stringify(problemsSession));
    navigate("/session-stats");
  };

  const renderSessionStatus = () => {
    if (problemsSession.length === 0) return null;

    const recentProblems = problemsSession.slice(-10);
    const totalProblems = problemsSession.length;

    return (
      <div className="flex gap-2 max-w-lg relative items-center">
        <div className="grid grid-cols-10 gap-2">
          {recentProblems.map((problem, index) => (
            <div
              key={index}
              className={`flex justify-center items-center w-8 h-8 p-1 rounded text-white text-lg ${
                problem.is_solved ? "bg-green-600" : "bg-red-600"
              }`}
            >
              {totalProblems - recentProblems.length + index + 1}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEloChange = (change) => {
    if (change === null) return null;

    const changeText = change > 0 ? `+${change}` : `${change}`;
    const changeColor = change > 0 ? "text-green-500" : "text-red-500";

    return <span className={`text-2xl ${changeColor}`}>{changeText}</span>;
  };

  const renderUserProblemElo = (userFieldElo, currentProblem, eloChange) => {
    // const lastProblem = problemsSession[problemsSession.length - 1];
    // const eloChange = lastProblem?.elo_change || 0;

    return (
      <>
        <div className="flex flex-col items-center gap-2">
          <span className="text-6xl flex items-center">
            {userFieldElo}
            {eloChange !== null && (
              <span
                className={`ml-2 text-3xl ${
                  eloChange > 0 ? "text-green-600" : "text-red-600"
                }`}
              >
                {eloChange > 0 ? `+${eloChange}` : `${eloChange}`}
              </span>
            )}
          </span>
          <span className="text-sm text-slate-400">Twoje ELO</span>
        </div>
        <span>vs</span>
        <div className="flex flex-col items-center gap-2">
          <span className="text-6xl">{currentProblem.problem_elo}</span>
          <span className="text-sm text-slate-400">ELO zadania</span>
        </div>
      </>
    );
  };

  // Nowa funkcja renderująca zmianę ELO

  // Nowa funkcja renderująca odpowiedzi i potwierdzanie
  const renderAnswers = (
    answers,
    selectedAnswer,
    showIcons,
    isAnswerChecked,
    handleAnswerClick,
    handleConfirmClick
  ) => {
    return (
      <ul className="grid grid-cols-2 gap-4">
        {answers.map((answer, index) => (
          <li key={index}>
            <button
              className={`w-full !max-w-none border border-slate-300 relative hover:bg-blue-100 text-black ${
                selectedAnswer === answer ? "!bg-blue-300" : "bg-transparent"
              } ${
                isAnswerChecked
                  ? answer.isCorrect
                    ? "!bg-green-100 !text-green-600"
                    : answer === selectedAnswer
                    ? "!bg-red-100 !text-red-600"
                    : "!text-black"
                  : "!text-black"
              } disabled:hover:bg-transparent`}
              onClick={() => handleAnswerClick(answer)}
              disabled={isAnswerChecked}
            >
              {showIcons && (
                <>
                  {answer === selectedAnswer && (
                    <span
                      className="absolute left-0 top-1/2 transform -translate-y-1/2 text-red-600 text-xl"
                      onClick={(e) => {
                        e.stopPropagation(); // Zatrzymuje propagację, aby nie wywołać handleAnswerClick
                        handleAnswerClick(null); // Resetowanie wyboru odpowiedzi
                      }}
                    >
                      ✗
                    </span>
                  )}
                  {answer === selectedAnswer && (
                    <span
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 text-green-600 text-xl"
                      onClick={(e) => {
                        e.stopPropagation(); // Zatrzymaj propagację
                        handleConfirmClick(); // Potwierdzenie odpowiedzi
                      }}
                    >
                      ✓
                    </span>
                  )}
                </>
              )}
              <RenderMarkdownWithLatex text={answer.text} />
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderHintAndNextButton = (
    isHintUsed,
    isAnswerChecked,
    isSubmitDisabled,
    handleHint,
    handleNextProblem,
    currentProblem
  ) => {
    return (
      <div className="flex flex-col items-center gap-1 w-full max-w-xl mt-2">
        {isAnswerChecked ? (
          <button
            className="w-full !max-w-none border border-slate-300 hover:bg-blue-100 text-black bg-transparent"
            onClick={handleNextProblem}
          >
            Następne zadanie
          </button>
        ) : (
          <>
            {!isHintUsed && (
              <button
                disabled={isSubmitDisabled}
                className="w-full !max-w-none border border-slate-300 hover:bg-blue-100 text-black bg-transparent"
                onClick={handleHint}
              >
                Pokaż podpowiedź
              </button>
            )}
            {isHintUsed && (
              <RenderMarkdownWithLatex text={currentProblem.hint} />
            )}
          </>
        )}
      </div>
    );
  };

  const renderSolutionButton = (
    isSolutionViewed,
    showSolution,
    isSolutionConfirmationVisible,
    isSubmitDisabled,
    isAnswerChecked,
    handleSolution,
    handleSolutionConfirmClick,
    setIsSolutionConfirmationVisible,
    currentProblem
  ) => {
    return (
      <div className="flex flex-col items-center gap-2 w-full max-w-xl mt-1">
        {!isSolutionViewed && !showSolution && (
          <button
            disabled={isSubmitDisabled && !isAnswerChecked}
            className={`w-full max-w-none border border-slate-300 hover:bg-blue-100 text-black flex justify-between items-center px-4 ${
              isSolutionConfirmationVisible ? "!bg-blue-300" : "bg-transparent"
            }`}
            onClick={handleSolution}
          >
            {isSolutionConfirmationVisible ? (
              <>
                <span
                  className="text-red-600 text-xl cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSolutionConfirmationVisible(false);
                  }}
                >
                  ✗
                </span>
                <span className="flex-grow text-center">Pokaż rozwiązanie</span>
                <span
                  className="text-green-600 text-xl cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSolutionConfirmClick();
                  }}
                >
                  ✓
                </span>
              </>
            ) : (
              <span className="w-full text-center">Pokaż rozwiązanie</span>
            )}
          </button>
        )}
        {(isSolutionViewed || showSolution) && (
          <RenderMarkdownWithLatex text={currentProblem.solution} />
        )}
      </div>
    );
  };

  return (
    <Page showNav>
      {showSessionStats ? (
        <StatisticsScreen
          problemsData={problemsSession}
          sessionStartTime={sessionStartTime}
        />
      ) : (
        <>
          <h2 className="text-2xl font-semibold text-gray-600">
            {" "}
            Zadanie numer {problemNumber}
          </h2>
          <div className="flex justify-between items-baseline gap-4">
            {renderUserProblemElo(userFieldElo, currentProblem, eloChange)}
          </div>
          <div className="flex flex-col gap-2 w-full max-w-xl text-center text-balance text-xl">
            <RenderMarkdownWithLatex text={currentProblem.question} />
            {renderAnswers(
              answers,
              selectedAnswer,
              showIcons,
              isAnswerChecked,
              handleAnswerClick,
              handleConfirmClick
            )}
          </div>

          {renderHintAndNextButton(
            isHintUsed,
            isAnswerChecked,
            isSubmitDisabled,
            handleHint,
            handleNextProblem,
            currentProblem
          )}
          {renderSolutionButton(
            isSolutionViewed,
            showSolution,
            isSolutionConfirmationVisible,
            isSubmitDisabled,
            isAnswerChecked,
            handleSolution,
            handleSolutionConfirmClick,
            setIsSolutionConfirmationVisible,
            currentProblem
          )}
        </>
      )}

      <div className="grid gap-2 max-w-lg">{renderSessionStatus()}</div>
      <div className="flex flex-col items-center gap-4 w-full max-w-xl mt-2">
        <button
          onClick={handleShowSessionStats}
          className="w-full !max-w-none border border-slate-300 hover:bg-blue-100 text-black bg-transparent"
        >
          {showSessionStats ? "Powrót do zadań" : "Statystyki sesji"}
        </button>

        <button
          onClick={handleEndSession}
          className="w-full !max-w-none border border-slate-300 hover:bg-blue-100 text-black bg-transparent"
        >
          Zakończ sesję
        </button>
      </div>
    </Page>
  );
};

export default SolveProblem;
